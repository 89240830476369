<template>
  <div>
    <nav
      class="max-w-[1440px] m-auto flex justify-between items-center fixed top-0 left-0 right-0 z-[1000] bg-white p-4"
    >
      <div class="flex items-center gap-10">
        <img class="h-14" src="../assets/iTechLogoPreta.webp" alt="itech" />
        <div class="desktop-menu">
          <a href="#home">{{ $t("home") }}</a>
          <a href="#development">{{ $t("development") }}</a>
          <a href="#about-us">{{ $t("about_us") }}</a>
          <a href="#contact">{{ $t("contact") }}</a>
        </div>
      </div>

      <div class="flex items-center gap-6">
        <div class="relative">
          <button
            @click="toggleDropdown"
            class="flex items-center gap-2 bg-dropdown p-2 rounded-md"
          >
            <component :is="currentFlag" class="w-6 h-6" />
            <span>{{ currentLanguage }}</span>
            <svg
              class="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                :d="dropdownOpen ? 'M19 15l-7-7-7 7' : 'M19 9l-7 7-7-7'"
              ></path>
            </svg>
          </button>

          <div
            v-if="dropdownOpen"
            class="absolute left-0 mt-2 w-[149.39px] p-2 bg-dropdown shadow-lg rounded-md z-[999]"
          >
            <button
              class="lang-button flex items-center gap-2 p-2 w-full transition-colors"
              @click="changeLanguage('pt-br')"
            >
              <Brasil class="w-6 h-6" /> <span>Português</span>
            </button>
            <button
              class="lang-button flex items-center gap-2 p-2 w-full transition-colors"
              @click="changeLanguage('en')"
            >
              <EstadosUnidos class="w-6 h-6" /> <span>English</span>
            </button>
            <button
              class="lang-button flex items-center gap-2 p-2 w-full transition-colors"
              @click="changeLanguage('es')"
            >
              <Espanha class="w-6 h-6" /> <span>Español</span>
            </button>
          </div>
        </div>

        <button class="button-desktop" @click.prevent="redirectTrack()">
          {{ $t("request_a_quote") }}
        </button>

        <div class="menu-hamburger" @click="toggleMenu">
          <MenuHamburger />
        </div>
      </div>
    </nav>

    <div
      class="mobile-menu fixed top-0 right-0 bottom-0 z-[1999] w-64 bg-white shadow-lg transform transition-transform duration-500 ease-in-out"
      :class="{ 'translate-x-0': menuOpen, 'translate-x-full': !menuOpen }"
    >
      <div class="flex flex-col justify-between h-full p-6">
        <div class="flex flex-col space-y-4">
          <div class="menu-hamburger" @click="toggleMenu">
            <MenuHamburger />
          </div>
          <a href="#home" class="py-2 text-left w-full"> Início </a>
          <a href="#development" class="py-2 text-left w-full">
            Desenvolvimento
          </a>
          <a href="#about-us" class="py-2 text-left w-full">Sobre nós</a>
          <a href="#contact" class="py-2 text-left w-full">Contato</a>
        </div>
        <button
          class="button py-3 px-6 rounded-3xl w-full"
          @click.prevent="redirectTrack()"
        >
          Pedir Orçamento
        </button>
      </div>
    </div>

    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Brasil from "@/assets/flags/Brasil.vue";
import MenuHamburger from "../assets/Icons/MenuHamburgerIcon.vue";
import EstadosUnidos from "@/assets/flags/EstadosUnidos.vue";
import Espanha from "@/assets/flags/Espanha.vue";

export default {
  name: "Navbar",
  components: { MenuHamburger, Brasil, EstadosUnidos, Espanha },
  data() {
    return {
      menuOpen: false,
      dropdownOpen: false,
      languages: {
        "pt-br": {
          name: "Português",
          flag: "Brasil",
        },
        en: {
          name: "English",
          flag: "EstadosUnidos",
        },
        es: {
          name: "Español",
          flag: "Espanha",
        },
      },
      currentLanguage: "Português",
      currentFlag: "Brasil",
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    changeLanguage(lang) {
      const selectedLanguage = this.languages[lang];
      this.currentLanguage = selectedLanguage.name;
      this.currentFlag = selectedLanguage.flag;
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      this.dropdownOpen = false;

      const url = new URL(window.location);
      url.searchParams.set("lang", lang);
      window.history.replaceState({}, "", url);
    },
    trackLead() {
      gtag("event", "conversion", {
        send_to: "AW-834192598/CpCqCMP_o9gZENaJ440D",
        event_callback: () => {},
      });
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "Lead");
      } else {
        console.error("O Pixel não está disponível.");
      }
    },
    redirectTrack() {
      this.trackLead();
      const phoneNumber = "5511969410757";
      const message =
        "Olá, tenho interesse em fazer um orçamento com vocês. Poderia me fornecer mais informações, por favor?";
      const encodedMessage = encodeURIComponent(message);
      const whatsApp = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.open(whatsApp, "_blank");
    },
    identifyLanguage() {
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get("lang");

      if (langParam && this.languages[langParam]) {
        this.changeLanguage(langParam);
      } else {
        const savedLocale = localStorage.getItem("locale") || "pt-br";
        this.changeLanguage(savedLocale);
      }
    },
  },
  mounted() {
    this.identifyLanguage();
  },
};
</script>


<style scoped>
img {
  transform: scale(1.2);
}
nav {
  padding: 16px;
  transition: background 0.2s ease;
}
nav a {
  font-weight: bold;
  color: black;
  transition: color 0.5s ease;
}
nav a:hover {
  color: var(--yellow-main);
}
.button {
  color: white;
  background-color: var(--yellow-main);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}
.button:hover {
  opacity: 0.8;
}
.button-desktop {
  padding: 0.75rem 1rem;
  border-radius: 1.5rem;
  background-color: var(--yellow-main);
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button-desktop:hover {
  opacity: 0.8;
}
.content {
  margin-top: 80px;
}
.mobile-menu {
  width: 75%;
  max-width: 300px;
  height: 100vh;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
}
.mobile-menu .button {
  text-align: center;
  background-color: var(--yellow-main);
  color: white;
}
.mobile-menu .button:hover {
  background-color: var(--yellow-dark);
}
.translate-x-full {
  transform: translateX(100%);
}
.translate-x-0 {
  transform: translateX(0);
}
.menu-hamburger {
  display: none;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  position: relative;
  z-index: 1001;
}
.desktop-menu {
  display: flex;
  gap: 20px;
}
.lang-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.bg-dropdown {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
}
.lang-button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  color: black;
}
.lang-button:hover {
  color: var(--yellow-main);
}
.menu-hamburger-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (min-width: 820px) and (max-width: 920px) {
  .menu-hamburger {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  .button-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-hamburger {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  .button-desktop {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  nav {
    border-bottom: 1px solid #00000014;
  }
}
</style>
