import { createI18n } from 'vue-i18n';

import messagesEn from './langs/english.json';
import messagesSp from './langs/spanish.json';
import messagesPtBr from './langs/pt-br.json';

const messages = {
    en: messagesEn,
    'pt-br': messagesPtBr,
    es: messagesSp
};

const savedLocale = localStorage.getItem('locale') || 'pt-br';

const i18n = createI18n({
    locale: savedLocale,
    fallbackLocale: 'en',
    messages,
});

export default i18n;
